import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation, withTranslation } from "react-i18next";
import { useMsal } from "@azure/msal-react";
//Ingka styling
import "@ingka/button/dist/style.css";
import "@ingka/choice/dist/style.css";
import "@ingka/focus/dist/style.css";
import "@ingka/forms/dist/style.css";
import "@ingka/hyperlink/dist/style.css";
import "@ingka/inline-message/dist/style.css";
import '@ingka/loading/dist/style.css';
import "@ingka/svg-icon/dist/style.css";
import "@ingka/text/dist/style.css";
import "@ingka/tooltip/dist/style.css";
import "@ingka/typography/dist/style.css";
import Choice, { ChoiceItem } from "@ingka/choice";
import InputField from "@ingka/input-field";
import Loading, { LoadingBall } from "@ingka/loading";
import Text from "@ingka/text";
import Tooltip from "@ingka/tooltip";

import {
  setServiceFailedSelect,
  setDeliveryPrice,
  selectDeliveryPrice,
  setSuperUser,
} from "./formModuleOneSlice";
import {
  selectIsEditing,
  selectCountrySettingsStatus,
} from "../CountrySettings/countrySettingsSlice";
import styles from "./formModuleOne.module.css";
import { CountrySettings } from "../CountrySettings/CountrySettings";
import sendAnalyticsEvent from "../../utils/useAnalyticsEventTracker";

// insecure first level check - this is separately confirmed on the server
const BelgiumAdminGroup = process.env.REACT_APP_BELGUIM_ADMIN;
const ItalyAdminGroup = process.env.REACT_APP_ITALY_ADMIN;
const SpainAdminGroup = process.env.REACT_APP_SPAIN_ADMIN;
const AustriaAdminGroup = process.env.REACT_APP_AUSTRIA_ADMIN;
const AustraliaAdminGroup = process.env.REACT_APP_AUSTRALIA_ADMIN;
const SwitzerlandAdminGroup = process.env.REACT_APP_SWITZERLAND_ADMIN;
const NorwayAdminGroup = process.env.REACT_APP_NORWAY_ADMIN;
const FranceAdminGroup = process.env.REACT_APP_FRANCE_ADMIN;
const SwedenAdminGroup = process.env.REACT_APP_SWEDEN_ADMIN;
const PortugalAdminGroup = process.env.REACT_APP_PORTUGAL_ADMIN;
const UsaAdminGroup = process.env.REACT_APP_USA_ADMIN;
const UkAdminGroup = process.env.REACT_APP_UK_ADMIN;
const IrelandAdminGroup = process.env.REACT_APP_IRELAND_ADMIN;
const CanadaAdminGroup = process.env.REACT_APP_CANADA_ADMIN;
const PolandAdminGroup = process.env.REACT_APP_POLAND_ADMIN;
const IndiaAdminGroup = process.env.REACT_APP_INDIA_ADMIN;
const CzechiaAdminGroup = process.env.REACT_APP_CZECHIA_ADMIN;
const HungaryAdminGroup = process.env.REACT_APP_HUNGARY_ADMIN;
const SlovakiaAdminGroup = process.env.REACT_APP_SLOVAKIA_ADMIN;
const KoreaAdminGroup = process.env.REACT_APP_KOREA_ADMIN;
const PreviewAdminGroup = process.env.REACT_APP_PREVIEW_ADMIN;

const AdminGroupArray = [BelgiumAdminGroup, ItalyAdminGroup, SpainAdminGroup, AustriaAdminGroup, AustraliaAdminGroup, SwitzerlandAdminGroup, NorwayAdminGroup, FranceAdminGroup, SwedenAdminGroup, PortugalAdminGroup, UsaAdminGroup, UkAdminGroup, IrelandAdminGroup, CanadaAdminGroup, PolandAdminGroup, IndiaAdminGroup, CzechiaAdminGroup, HungaryAdminGroup, SlovakiaAdminGroup, KoreaAdminGroup, PreviewAdminGroup]

const AdminGroupChecker = (arrayOfGroups) =>
  arrayOfGroups.some(
    group => AdminGroupArray.includes(group)
  );


const FormModuleOneComp = () => {
  const [deliveryPriceInputIsVisible, setDeliveryPriceInputIsVisible] = useState(false);
  const isEditing = useSelector(selectIsEditing);
  const status = useSelector(selectCountrySettingsStatus);
  const { accounts } = useMsal();
  const [selectedChoice, setSelectedChoice] = useState(null);

  /*
  full validation is in backend.
  this use effect just checks if the user has an admin group
  in order to display the country settings blue button toggle
  */

  useEffect(() => {
    if (accounts[0]?.idTokenClaims?.groups?.[0]) {
      let userAdminGroups = accounts[0]?.idTokenClaims?.groups;
      if (AdminGroupChecker(userAdminGroups)) {
        dispatch(setSuperUser({ superUser: true }));
      }
    } else {
      dispatch(setSuperUser({ superUser: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts, AdminGroupArray]);

  // Selectors
  const deliveryPriceValue = useSelector(selectDeliveryPrice);

  // Dispatch
  const dispatch = useDispatch();

  // Translations
  const { t } = useTranslation();

  // Handlers
  const serviceFailedSelectHandler = (evt) => {
    dispatch(setServiceFailedSelect({ serviceFailed: evt }));
    // Check if the clicked item is the currently selected one
    if (evt === selectedChoice) {
      // Reset the input field value when the same item is clicked again
      dispatch(setDeliveryPrice({ deliveryPrice: "" }));
      setSelectedChoice(null);
    } else {
      // Set the selectedChoice state to the clicked item
      setSelectedChoice(evt);
    }
    // to enable/disable deliveryPrice input
    if (evt === "mod1Title.Option3") {
      setDeliveryPriceInputIsVisible(false);
    } else {
      setDeliveryPriceInputIsVisible(true);
    }
  };

  const deliveryPriceHandler = (evt) => {
    dispatch(setDeliveryPrice({ deliveryPrice: evt.target.value }));
  };

  const gaEventTracker = sendAnalyticsEvent('delivery_information');

  return (
    < >
      <div>
        <div
          style={{ display: isEditing ? "block" : "none" }}
          className="lang-edit-wrapper"
        >
          {status === 'loading' && <div className={styles.spinner2}>
            <Loading text={t("loading")} labelTransitions>
              <LoadingBall />
            </Loading>
          </div>
          }{status === 'idle' &&
            <CountrySettings />}
        </div>
      </div>
      <div className={styles.moduleContainer}>
        <div className={styles.sectionContainer}>

          <div className={styles.module}>
            <div className={styles.titles}>
              <Text tagName="h1" headingSize="l">
                {t("mod1Header.Text")}
              </Text>
            </div>
            <span className={styles.undertitle}>{t("mod1SubHeader.Text")}</span>
            <div className={styles.subtitle}>
              <Text tagName="h4" headingSize="s">
                {t("mod1Title.Text")}
              </Text>
              <Tooltip tooltipText={t("mod1Title.Tooltip")} />
            </div>
            <div className={styles.inputsContainer}>
              <div className={styles.optionContainer}>
                <Choice deselectable>
                  <ChoiceItem
                    title={t("mod1Title.Option1")}
                    id="mod1Title.Option1"
                    isSelected={selectedChoice === "mod1Title.Option1"}
                    onClick={(evt) => { gaEventTracker('YesWithNotice'); return serviceFailedSelectHandler(evt, dispatch) }}
                  ></ChoiceItem>
                  <ChoiceItem
                    title={t("mod1Title.Option2")}
                    id="mod1Title.Option2"
                    isSelected={selectedChoice === "mod1Title.Option2"}
                    onClick={(evt) => { gaEventTracker('YesWithNoNotice'); return serviceFailedSelectHandler(evt, dispatch) }}
                  ></ChoiceItem>
                  <ChoiceItem
                    title={t("mod1Title.Option3")}
                    id="mod1Title.Option3"
                    isSelected={selectedChoice === "mod1Title.Option3"}
                    onClick={(evt) => { gaEventTracker('No'); return serviceFailedSelectHandler(evt, dispatch) }}
                  ></ChoiceItem>
                </Choice>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.userContainer}>
          <div className={styles.userEntryContainer}>
            {deliveryPriceInputIsVisible && (
              <div className={styles.hideShow}>
                <label
                  htmlFor="mod1_deliveryPrice"
                  className={styles.userLabel}
                >
                  {t("mod1Input.Label")}
                </label>
                <InputField
                  type="number"
                  step="any"
                  value={deliveryPriceValue || ""}
                  min="0"
                  onChange={(evt) => { gaEventTracker('DeliveryPriceInput'); return deliveryPriceHandler(evt, dispatch) }} />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const FormModuleOne = withTranslation()(FormModuleOneComp);
export { FormModuleOne };
