import React, { useState, useEffect } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMsal } from "@azure/msal-react";
import Choice, { ChoiceItem } from "@ingka/choice";
import Loading, { LoadingBall } from "@ingka/loading";
import Text from "@ingka/text";
import "@ingka/choice/dist/style.css";
import "@ingka/focus/dist/style.css";
import "@ingka/text/dist/style.css";
import "@ingka/tooltip/dist/style.css";
import styles from "./categorySelector.module.css";
import {
  selectIsEditing,
  selectCountrySettingsStatus,
} from "../CountrySettings/countrySettingsSlice";
import { CountrySettings } from "../CountrySettings/CountrySettings";
import sendAnalyticsEvent from "../../utils/useAnalyticsEventTracker";
import { CategoryModuleOne } from "../CategoryModuleOne/CategoryModuleOne";
import { CategoryModuleTwo } from "../CategoryModuleTwo/CategoryModuleTwo";
import { CategoryModuleThree } from "../CategoryModuleThree/CategoryModuleThree";
import { resetState as resetCategory1State, setSuperUser } from "../FormModuleOne/formModuleOneSlice";
import { resetState as resetCategory2State } from "../FormModuleTwo/formModuleTwoSlice";
import { resetState as resetCategory3State } from "../FormModuleThree/formModuleThreeSlice";
import { resetState as resetCalculatorState } from "../CalculatorResults/calculatorResultsSlice";

const BelgiumAdminGroup = process.env.REACT_APP_BELGUIM_ADMIN;
const ItalyAdminGroup = process.env.REACT_APP_ITALY_ADMIN;
const SpainAdminGroup = process.env.REACT_APP_SPAIN_ADMIN;
const AustriaAdminGroup = process.env.REACT_APP_AUSTRIA_ADMIN;
const AustraliaAdminGroup = process.env.REACT_APP_AUSTRALIA_ADMIN;
const SwitzerlandAdminGroup = process.env.REACT_APP_SWITZERLAND_ADMIN;
const NorwayAdminGroup = process.env.REACT_APP_NORWAY_ADMIN;
const FranceAdminGroup = process.env.REACT_APP_FRANCE_ADMIN;
const SwedenAdminGroup = process.env.REACT_APP_SWEDEN_ADMIN;
const PortugalAdminGroup = process.env.REACT_APP_PORTUGAL_ADMIN;
const UsaAdminGroup = process.env.REACT_APP_USA_ADMIN;
const UkAdminGroup = process.env.REACT_APP_UK_ADMIN;
const IrelandAdminGroup = process.env.REACT_APP_IRELAND_ADMIN;
const CanadaAdminGroup = process.env.REACT_APP_CANADA_ADMIN;
const PolandAdminGroup = process.env.REACT_APP_POLAND_ADMIN;
const IndiaAdminGroup = process.env.REACT_APP_INDIA_ADMIN;
const CzechiaAdminGroup = process.env.REACT_APP_CZECHIA_ADMIN;
const HungaryAdminGroup = process.env.REACT_APP_HUNGARY_ADMIN;
const SlovakiaAdminGroup = process.env.REACT_APP_SLOVAKIA_ADMIN;
const KoreaAdminGroup = process.env.REACT_APP_KOREA_ADMIN;
const PreviewAdminGroup = process.env.REACT_APP_PREVIEW_ADMIN;

const AdminGroupArray = [BelgiumAdminGroup, ItalyAdminGroup, SpainAdminGroup, AustriaAdminGroup, AustraliaAdminGroup, SwitzerlandAdminGroup, NorwayAdminGroup, FranceAdminGroup, SwedenAdminGroup, PortugalAdminGroup, UsaAdminGroup, UkAdminGroup, IrelandAdminGroup, CanadaAdminGroup, PolandAdminGroup, IndiaAdminGroup, CzechiaAdminGroup, HungaryAdminGroup, SlovakiaAdminGroup, KoreaAdminGroup, PreviewAdminGroup]

const AdminGroupChecker = (arrayOfGroups) =>
  arrayOfGroups.some(
    group => AdminGroupArray.includes(group)
  );

const CategorySelectorComp = () => {
  const status = useSelector(selectCountrySettingsStatus);
  const { accounts } = useMsal();

  useEffect(() => {
    if (accounts[0]?.idTokenClaims?.groups?.[0]) {
      let userAdminGroups = accounts[0]?.idTokenClaims?.groups;
      if (AdminGroupChecker(userAdminGroups)) {
        dispatch(setSuperUser({ superUser: true }));
      }
    } else {
      dispatch(setSuperUser({ superUser: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts, AdminGroupArray]);

  const gaEventTracker = sendAnalyticsEvent('delivery_information');
  const isEditing = useSelector(selectIsEditing);
  // Dispatch
  const dispatch = useDispatch();
  // Translations
  const { t } = useTranslation();

  const [activatedCategory, setActivatedcategory] = useState("none");

  const toggleSelect = (category) => {
    setActivatedcategory(category !== activatedCategory ? category : "none")
    dispatch(resetCategory1State());
    dispatch(resetCategory2State());
    dispatch(resetCategory3State());
    dispatch(resetCalculatorState());
  }
  // const [selectedChoice, setSelectedChoice] = useState(null);
  return (
    <>
      <div>
        <div
          style={{ display: isEditing ? "block" : "none" }}
          className="lang-edit-wrapper"
        >
          {status === 'loading' && <div className={styles.spinner2}>
            <Loading text={t("loading")} labelTransitions>
              <LoadingBall />
            </Loading>
          </div>
          }{status === 'idle' &&
            <CountrySettings />}
        </div>
      </div>
      <div className={styles.moduleContainer}>
        <div className={styles.sectionContainer}>
          <div className={styles.module}>
            <div className={styles.titles}>
              <Text tagName="h1" headingSize="l">
                {t("categorySelectorHeader.Text")}
              </Text>
            </div>
            <span className={styles.undertitle}>{t("categorySelectorSubHeader.Text")}</span>
            <div className={styles.inputsContainer}>
              <div className={styles.optionContainer}>
                <Choice deselectable>
                  <ChoiceItem
                    title={t("category1.Header")}
                    id="category1.Header"
                    onClick={() => { gaEventTracker('YesWithNotice'); toggleSelect("CategoryModule1"); }}
                  ></ChoiceItem>
                  <ChoiceItem
                    title={t("category2.Header")}
                    id="category2.Header"
                    onClick={() => { gaEventTracker('YesWithNoNotice'); toggleSelect("CategoryModule2"); }}
                  ></ChoiceItem>
                  <ChoiceItem
                    title={t("category3.Header")}
                    id="category3.Header"
                    onClick={() => { gaEventTracker('No'); toggleSelect("CategoryModule3"); }}
                  ></ChoiceItem>
                </Choice>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.sectionContainer}>
          <div className={styles.module}>
            {activatedCategory === "CategoryModule1" ? <CategoryModuleOne /> : activatedCategory === "CategoryModule2" ? <CategoryModuleTwo /> : activatedCategory === "CategoryModule3" ? <CategoryModuleThree /> : <></>}
            <div className={styles.separator}></div>
          </div>
        </div>
      </div>
    </>
  )
}
const CategorySelector = withTranslation()(CategorySelectorComp);
export { CategorySelector };