import { createSlice , createAsyncThunk} from "@reduxjs/toolkit";
import { putCountrySettings } from "./countrySettingsAPI";
const initialState = {
  response: null,
  status: "idle",
  isEditing: false,
};

export const allLanguageCodes = ["en-US","nl-BE","fr-BE","it-IT","ca-ES","es-ES", "eu-ES", "gl-ES", "de-AT", "en-AU", "en-CH", "fr-CH", "de-CH", "it-CH", "no-NO", "fr-FR", "sv-SE", "pt-PT", "en-PT", "en-GB", "en-IE", "fr-CA", "en-CA",  "pl-PL", "en-IN", "sk-SK", "hu-HU", "cs-CZ", "kr-KR"];

export const getLastSelectedCountry = (defaultLang) => {
  // let translationsLang = 'en-US';
  const existingLanguage = localStorage.getItem('selected-language');
  if(existingLanguage && allLanguageCodes.includes(existingLanguage)){
    return existingLanguage;
  }
  return defaultLang === 'en-US' ? 'en-US' : defaultLang;
}


export const putCountrySettingsAsync = createAsyncThunk(
  "putCountrySettings",
  async(countryData,{ rejectWithValue }) => {
    try {
      const response = await putCountrySettings(countryData);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (err) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data)
    }
  }
);


export const countrySettingsSlice = createSlice({
  name: "countrySettings",
  initialState,
  reducers: {
    toggleIsEditing: (state) => {
      state.isEditing = !state.isEditing;
    },
    clearCountrySettingsError: (state) => {
      state.status = "idle"
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(putCountrySettingsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(putCountrySettingsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.response = action.payload;
      })
      .addCase(putCountrySettingsAsync.rejected, (state, action) => {
        state.status = "error";
        state.response = action.payload;
      });
  },
});
export const { toggleIsEditing } = countrySettingsSlice.actions;
export const { clearCountrySettingsError } = countrySettingsSlice.actions;

export const selectLanguageCode = (state) =>
state.appData.langCode;
export const selectPricePerMeal = (state) =>
state.appData.pricePerMeal;
export const selectPricePerKm= (state) =>
state.appData.pricePerKm;
export const selectArtCompensationLimit = (state) =>
state.appData.artCompensationLimit;
export const selectNonArtCompensationLimit= (state) =>
state.appData.nonArtCompensationLimit;
export const selectCurrencySign = (state) => state.appData.currencySign;
export const selectIsEditing = (state) =>
state.countrySettings.isEditing;
export const selectCountrySettingsStatus = (state) =>
state.countrySettings.status;
export const selectCountrySettingsResponse = (state) =>
state.countrySettings.response;
